<template>
    <div class="modal-overlay">
        <div class="modal-content">
            <span class="close" @click="$emit('close')">&times;</span>
            <h2>Pickup Details</h2>
            <form @submit.prevent="handleSubmitWrapper">
                <div class="form-group">
                    <input v-model="pickupDetails.house_no" type="text" placeholder="House Number" class="input-field"
                        required />
                    <input v-model="pickupDetails.street_address" type="text" placeholder="Street Address"
                        class="input-field" required />
                    <input v-model="pickupDetails.pickup_datetime" type="datetime-local" class="input-field" required />
                    <input v-model="pickupDetails.contact_mobile" type="tel" placeholder="Contact Number"
                        class="input-field" required />
                </div>
                <button type="submit" class="submit-button" :disabled="loading">
                    <span v-if="loading" class="loading-spinner"></span>
                    {{ loading ? "Creating Order..." : "Create Order" }}
                </button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CheckoutModal',
    props: {
        pickupDetails: { type: Object, required: true }
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        async handleSubmitWrapper() {
            this.loading = true;
            try {
                // Format the pickup_datetime to the expected format
                const formattedDatetime = new Date(this.pickupDetails.pickup_datetime).toISOString();

                // Update the pickupDetails with the formatted datetime
                this.pickupDetails.pickup_datetime = formattedDatetime;

                // Emit the formatted pickupDetails
                await this.$emit('submit', this.pickupDetails);
            } catch (error) {
                console.error("Error submitting order:", error);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
/* Same styles as login modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Overlay with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    /* Modal width */
    width: 100%;
    /* Take full width for responsiveness */
    max-height: 75vh;
    /* 75% of the viewport height */
    overflow-y: auto;
    /* Scroll if content is longer */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.text-button {
    margin-top: 10px;
    background: none;
    border: none;
    color: var(--dl-color-theme-primary1);
    cursor: pointer;
    text-decoration: underline;
}

h2 {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    text-align: center;
    color: #333;
}

.close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 2rem;
    cursor: pointer;
    color: #333;
}

.form-group {
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
}

.input-field {
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.3s;
}

.input-field:focus {
    border-color: var(--dl-color-theme-primary1);
    /* Green border on focus */
    outline: none;
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);
    /* Subtle green shadow */
}

.submit-button {
    /* background-color: #4caf50; */
    background-color: var(--dl-color-theme-primary1);
    /* Green button */
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: var(--dl-color-theme-neutral-dark);
    /* Darker green on hover */
}

.loading-spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 8px;
    /* Space between spinner and text */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
