<template>
  <div class="steps-container1 thq-section-padding">
    <div class="steps-max-width thq-section-max-width">
      <div class="steps-container2 thq-grid-2">
        <div class="steps-section-header">
          <h2 class="thq-heading-2">Discover the Benefits of Our Services</h2>
          <p class="thq-body-large">
            We provide comprehensive junk removal for residential, commercial, and industrial clients. Whether you're
            clearing out your home, office, or construction site, we've got you covered.
          </p>
          <div class="steps-actions">
            <button @click="schedulePickup" class="thq-button-animated thq-button-filled steps-button">
              <span class="thq-body-small">Schedule a Pickup</span>
            </button>
          </div>
        </div>
        <div class="steps-container3">
          <div class="steps-container4 thq-card">
            <h2 class="thq-heading-2">{{ step1Title }}</h2>
            <span class="steps-text14 thq-body-small">
              {{ step1Description }}
            </span>
            <label class="steps-text15 thq-heading-3">01</label>
          </div>
          <div class="steps-container5 thq-card">
            <h2 class="thq-heading-2">{{ step2Title }}</h2>
            <span class="steps-text17 thq-body-small">
              {{ step2Description }}
            </span>
            <label class="steps-text18 thq-heading-3">02</label>
          </div>
          <div class="steps-container6 thq-card">
            <h2 class="thq-heading-2">{{ step3Title }}</h2>
            <span class="steps-text20 thq-body-small">
              {{ step3Description }}
            </span>
            <label class="steps-text21 thq-heading-3">03</label>
          </div>
          <div class="steps-container7 thq-card">
            <h2 class="thq-heading-2">{{ step4Title }}</h2>
            <span class="steps-text23 thq-body-small">
              {{ step4Description }}
            </span>
            <label class="steps-text24 thq-heading-3">04</label>
          </div>
        </div>
      </div>
    </div>



    <!-- Zipcode Modal -->
    <zipcode-modal v-if="showZipcodeModal" :submitZipcode="handleZipcodeSubmit" @close="closeZipcodeModal" />

    <!-- Items Modal -->
    <items-modal v-if="showItemsModal" :items="items" @addItem="addToCart" @checkout="proceedToCheckout"
      @close="closeItemsModal" />

    <!-- Checkout Modal -->
    <checkout-modal v-if="showCheckoutModal" :pickupDetails="pickupDetails" @submit="openSummaryModal"
      @close="closeCheckoutModal" />

    <!-- Summary Modal -->
    <summary-modal v-if="showSummaryModal" :locationData="locationData" :pickupDetails="pickupDetails" :cart="cart"
      @close="closeSummaryModal" @confirmOrder="createOrder" />


    <!-- Login Modal -->
    <login-modal v-if="showLoginModal" :title="'Login'" :fields="loginFields" :buttonText="'Login'"
      :textButtonText="'Forgot Password?'" :handleTextButton="openForgotPasswordModal" :handleSubmit="login"
      @close="closeLoginModal" />


    <!-- Forgot Password Modal -->
    <login-modal v-if="showForgotPasswordModal" :title="'Forgot Password'" :fields="forgotPasswordFields"
      :buttonText="'Request OTP'" :textButtonText="'Already have an account? Login'" :handleTextButton="openLoginModal"
      :handleSubmit="requestOtp" @close="closeForgotPasswordModal" />


    <!-- Reset Password Modal -->
    <login-modal v-if="showResetPasswordModal" :title="'Reset Password'" :fields="resetPasswordFields"
      :buttonText="'Reset Password'" :textButtonText="'Resend OTP'" :handleTextButton="resendOtp"
      :handleSubmit="resetPassword" @close="closeResetPasswordModal" />

    <login-modal v-if="showVerifyModal" :title="'Verify your account'" :fields="verifyFields" :buttonText="'Verify'"
      :textButtonText="'Resend OTP'" :handleTextButton="resendOtp" :handleSubmit="verifyAccount"
      @close="closeVerifyModal" />

    <!-- Signup Modal -->
    <signup-modal v-if="showSignupModal" :title="'Sign Up'" :fields="signupFields" :buttonText="'Sign Up'"
      :handleSubmit="signup" @close="closeSignupModal" />

  </div>
</template>

<script>

import orderMixin from './mixins/orderMixin';
import ZipcodeModal from './order/zipcode.vue';
import ItemsModal from './order/items.vue';
import CheckoutModal from './order/checkout.vue';
import SummaryModal from './order/summary.vue';
import LoginModal from './auth/login.vue';
import SignupModal from './auth/signup.vue'
import AuthMixin from './mixins/authMixin';

export default {
  name: 'Steps',
  mixins: [orderMixin, AuthMixin],
  components: {
    ZipcodeModal,
    ItemsModal,
    CheckoutModal,
    SummaryModal,
    LoginModal,
    SignupModal,
  },
  //mixins: [AuthMixin],
  methods: {
    schedulePickup() {
      console.log('Schedule Pickup button clicked'); // Debugging statement
      this.openZipcodeModal(); // Opens Zipcode modal from mixin
    }
  },
  props: {
    step1Description: {
      type: String,
      default:
        'Contact us to schedule a convenient time for junk collection at your location.',
    },
    step3Description: {
      type: String,
      default:
        'We ensure that the junk collected is disposed of in an environmentally friendly manner, following all regulations.',
    },
    step2Title: {
      type: String,
      default: 'Collection Day',
    },
    step2Description: {
      type: String,
      default:
        'On the scheduled day, our team will arrive at your location to collect the junk.',
    },
    step1Title: {
      type: String,
      default: 'Schedule a Pickup',
    },
    step3Title: {
      type: String,
      default: 'Eco-Friendly Disposal',
    },
    step4Description: {
      type: String,
      default:
        'Our goal is to provide excellent service and ensure our customers are satisfied with our junk removal services.',
    },
    step4Title: {
      type: String,
      default: 'Customer Satisfaction',
    },
  },
}
</script>

<style scoped>
.steps-container1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.steps-max-width {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.steps-container2 {
  align-items: start;
}

.steps-section-header {
  gap: var(--dl-space-space-oneandhalfunits);
  top: 10%;
  display: flex;
  position: sticky;
  align-items: flex-start;
  flex-direction: column;
}

.steps-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}

.steps-container3 {
  grid-area: span 1/span 1/span 1/span 1;
}

.steps-container4 {
  top: 10%;
  position: sticky;
  transform: rotate(-2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent1);
}

.steps-text14 {
  text-align: center;
}

.steps-text15 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.steps-container5 {
  top: 10%;
  position: sticky;
  transform: rotate(2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent2);
}

.steps-text17 {
  text-align: center;
}

.steps-text18 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.steps-container6 {
  top: 10%;
  position: sticky;
  transform: rotate(-2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent1);
}

.steps-text20 {
  text-align: center;
}

.steps-text21 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.steps-container7 {
  top: 10%;
  position: sticky;
  transform: rotate(2deg);
  background-color: var(--dl-color-theme-accent2);
}

.steps-text23 {
  text-align: center;
}

.steps-text24 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

@media(max-width: 991px) {
  .steps-max-width {
    flex-direction: column;
  }
}

@media(max-width: 767px) {
  .steps-section-header {
    position: static;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .steps-actions {
    width: 100%;
    align-self: flex-start;
  }

  .steps-container4 {
    width: 100%;
  }

  .steps-container5 {
    width: 100%;
  }

  .steps-container6 {
    width: 100%;
  }

  .steps-container7 {
    width: 100%;
  }
}

@media(max-width: 479px) {
  .steps-button {
    width: 100%;
  }
}
</style>
