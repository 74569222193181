// orderMixin.js
import axios from 'axios';

export default {
  data() {
    return {
      items: [],
      pickupDetails: {
        house_no: '',
        street_address: '',
        pickup_datetime: '',
        contact_mobile: ''
      },
      cart: [], 
      locationData: null,
      showZipcodeModal: false,
      showItemsModal: false,
      showCheckoutModal: false,
      showSummaryModal: false,
    };
  },
  methods: {

    openZipcodeModal() {
        this.showZipcodeModal = true;
      },
      closeZipcodeModal() {
        this.showZipcodeModal = false;
      },
      closeCheckoutModal() {
        this.showCheckoutModal = false;
        this.showItemsModal = false;
        this.showZipcodeModal = false;
      },
      openItemsModal() {
        this.showItemsModal = true;
      },
      closeItemsModal() {
        this.showItemsModal = false;
      },
      openSummaryModal() {
        this.showCheckoutModal = false;
        this.showSummaryModal = true;
      },

    async handleZipcodeSubmit(zipcode) {
      try {
        const locationResponse = await axios.get(`${process.env.VUE_APP_ZIPCODE}${zipcode}`);
        this.locationData = locationResponse.data;
        if (!this.locationData) throw new Error("No location found for this zipcode.");

        const serviceLocationResponse = await axios.post(process.env.VUE_APP_SERVICE_LOCATIONS, {
          location_name: this.locationData.city
        });
        const serviceLocation = serviceLocationResponse.data;

        if (!serviceLocation || serviceLocation.length === 0) throw new Error("No service location found for this zipcode.");

        const itemsResponse = await axios.post(process.env.VUE_APP_ITEMS, {
          location_id: serviceLocation[0].id
        });
        this.items = itemsResponse.data;

        if (!this.items || this.items.length === 0) throw new Error("No items found for this zipcode.");

        this.closeZipcodeModal();
        this.showItemsModal = true;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error("There was a problem processing your request. Please try a different zipcode.");
      }
    },
    
    addToCart(selectedItems) {
      selectedItems.forEach(newItem => {
        const existingItem = this.cart.find(cartItem => cartItem.item_id === newItem.id);
        if (existingItem) {
          existingItem.quantity = newItem.quantity;
        } else {
          this.cart.push({
            item_id: newItem.id,
            quantity: newItem.quantity,
            name: newItem.name
          });
        }
      });
    },

    proceedToCheckout(selectedItems) {
      if (!localStorage.getItem('token')) {
        alert('Please log in to checkout.');
        this.showLoginModal = true;
        return;
      }

      this.addToCart(selectedItems);
      this.showItemsModal = false;
      this.showCheckoutModal = true;
    },

    async createOrder() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('Please log in to place an order.');
        this.showLoginModal = true;
        return;
      }

      try {
        const response = await axios.post(process.env.VUE_APP_ORDER, {
          collector_id: 2,
          longitude: String(this.locationData.longitude),
          latitude: String(this.locationData.latitude),
          ...this.pickupDetails,
          order_items: this.cart.map(item => ({
            item_id: item.item_id,
            quantity: item.quantity
          }))
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`
          }
        });

        alert(response.data.message);
        this.showSummaryModal = false;
      } catch (error) {
        console.error('Order creation failed:', error);
      }
    },
  }
};
