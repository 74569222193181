<template>
    <div class="modal-overlay">
        <div class="modal-content">
            <span class="close" @click="$emit('close')">&times;</span>
            <h2>{{ title }}</h2>
            <form @submit.prevent="handleSubmit">
                <div class="form-group">
                    <input v-for="field in fields" :key="field.name" v-model="field.model" :type="field.type"
                        :placeholder="field.placeholder" class="input-field" required />
                </div>
                <button type="submit" class="submit-button"> {{ buttonText }}</button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true },
        fields: { type: Array, required: true },
        buttonText: { type: String, required: true },
        handleSubmit: { type: Function, required: true }
    }
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Overlay with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    /* Modal width */
    width: 100%;
    /* Take full width for responsiveness */
    max-height: 75vh;
    /* 75% of the viewport height */
    overflow-y: auto;
    /* Scroll if content is longer */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

h2 {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    text-align: center;
    color: #333;
}

.close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 2rem;
    cursor: pointer;
    color: #333;
}

.form-group {
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
}

.input-field {
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.3s;
}

.input-field:focus {
    border-color: var(--dl-color-theme-primary1);
    /* Green border on focus */
    outline: none;
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);
    /* Subtle green shadow */
}

.submit-button {
    background-color: var(--dl-color-theme-primary1);
    /* Green button */
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: var(--dl-color-theme-neutral-dark);
    /* Darker green on hover */
}
</style>