<template>
  <div class="thq-section-padding">
    <div class="thq-section-max-width">
      <div class="cta-accent2-bg">
        <div class="cta-accent1-bg">
          <div class="cta-container2">
            <div class="cta-content">
              <span class="thq-heading-2">{{ heading1 }}</span>
              <p class="thq-body-large">{{ content1 }}</p>
            </div>
            <div class="cta-actions">
              <button @click="schedulePickup" type="button" class="thq-button-filled cta-button">
                {{ action1 }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Zipcode Modal -->
    <zipcode-modal v-if="showZipcodeModal" :submitZipcode="handleZipcodeSubmit" @close="closeZipcodeModal" />

    <!-- Items Modal -->
    <items-modal v-if="showItemsModal" :items="items" @addItem="addToCart" @checkout="proceedToCheckout"
      @close="closeItemsModal" />

    <!-- Checkout Modal -->
    <checkout-modal v-if="showCheckoutModal" :pickupDetails="pickupDetails" @submit="openSummaryModal"
      @close="closeCheckoutModal" />

    <!-- Summary Modal -->
    <summary-modal v-if="showSummaryModal" :locationData="locationData" :pickupDetails="pickupDetails" :cart="cart"
      @close="closeSummaryModal" @confirmOrder="createOrder" />


    <!-- Login Modal -->
    <login-modal v-if="showLoginModal" :title="'Login'" :fields="loginFields" :buttonText="'Login'"
      :textButtonText="'Forgot Password?'" :handleTextButton="openForgotPasswordModal" :handleSubmit="login"
      @close="closeLoginModal" />


    <!-- Forgot Password Modal -->
    <login-modal v-if="showForgotPasswordModal" :title="'Forgot Password'" :fields="forgotPasswordFields"
      :buttonText="'Request OTP'" :textButtonText="'Already have an account? Login'" :handleTextButton="openLoginModal"
      :handleSubmit="requestOtp" @close="closeForgotPasswordModal" />


    <!-- Reset Password Modal -->
    <login-modal v-if="showResetPasswordModal" :title="'Reset Password'" :fields="resetPasswordFields"
      :buttonText="'Reset Password'" :textButtonText="'Resend OTP'" :handleTextButton="resendOtp"
      :handleSubmit="resetPassword" @close="closeResetPasswordModal" />

    <login-modal v-if="showVerifyModal" :title="'Verify your account'" :fields="verifyFields" :buttonText="'Verify'"
      :textButtonText="'Resend OTP'" :handleTextButton="resendOtp" :handleSubmit="verifyAccount"
      @close="closeVerifyModal" />

    <!-- Signup Modal -->
    <signup-modal v-if="showSignupModal" :title="'Sign Up'" :fields="signupFields" :buttonText="'Sign Up'"
      :handleSubmit="signup" @close="closeSignupModal" />

  </div>
</template>

<script>
import orderMixin from './mixins/orderMixin';
import ZipcodeModal from './order/zipcode.vue';
import ItemsModal from './order/items.vue';
import CheckoutModal from './order/checkout.vue';
import SummaryModal from './order/summary.vue';
import LoginModal from './auth/login.vue';
import AuthMixin from './mixins/authMixin';


export default {
  name: 'CTA',
  mixins: [orderMixin, AuthMixin],
  components: {
    ZipcodeModal,
    ItemsModal,
    CheckoutModal,
    SummaryModal,
    LoginModal
  },
  //mixins: [AuthMixin],
  methods: {
    schedulePickup() {
      console.log('Schedule Pickup button clicked'); // Debugging statement
      this.openZipcodeModal(); // Opens Zipcode modal from mixin
    }
  },
  props: {
    heading1: {
      type: String,
      default: 'Clear Out Your Space Today!',
    },
    content1: {
      type: String,
      default:
        'Contact us now to schedule a pickup and free your home or business from unnecessary clutter.',
    },
    action1: {
      type: String,
      default: 'Schedule Pickup',
    },
  }
}
</script>


<style scoped>
.cta-accent2-bg {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(1deg) skew(0deg, 0deg);
  align-self: stretch;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  justify-content: space-between;
  transform-style: preserve-3d;
  background-color: var(--dl-color-theme-accent2);
}

.cta-accent2-bg:hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

.cta-accent1-bg {
  width: 100%;
  display: flex;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-2deg) skew(0deg, 0deg);
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  justify-content: space-between;
  transform-style: preserve-3d;
  background-color: var(--dl-color-theme-accent1);
}

.cta-container2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(1deg) skew(0deg, 0deg);
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-cardradius);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-sixunits);
}

.cta-container2:hover {
  color: var(--dl-color-theme-neutral-light);
  background-color: var(--dl-color-theme-neutral-dark);
}

.cta-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.cta-actions {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

@media(max-width: 767px) {
  .cta-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media(max-width: 479px) {
  .cta-actions {
    flex-wrap: wrap;
    align-self: stretch;
    justify-content: center;
  }

  .cta-button {
    flex: 1;
  }
}
</style>
