<template>
  <div class="home-container">
    <app-navbar></app-navbar>
    <app-hero id="hero"></app-hero>
    <app-features1 id="features1"></app-features1>
    <app-cta id="cta"></app-cta>
    <app-features2 id="features2"></app-features2>
    <app-steps id="steps"></app-steps>
    <app-testimonial id="testimonial"></app-testimonial>
    <app-contact id="contact"></app-contact>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppNavbar from '../components/navbar'
import AppHero from '../components/hero'
import AppFeatures1 from '../components/features1'
import AppCta from '../components/cta'
import AppFeatures2 from '../components/features2'
import AppSteps from '../components/steps'
import AppTestimonial from '../components/testimonial'
import AppContact from '../components/contact'
import AppFooter from '../components/footer'

export default {
  name: 'Home',
  components: {
    AppNavbar,
    AppHero,
    AppFeatures1,
    AppCta,
    AppFeatures2,
    AppSteps,
    AppTestimonial,
    AppContact,
    AppFooter,
  },
  metaInfo: {
    title: 'PG Junk Removal',
  },


}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
</style>
