// src/mixins/authMixin.js
export default {
    data() {
      return {
        showLoginModal: false,
        showSignupModal: false,
        showForgotPasswordModal: false,
        showResetPasswordModal: false,
        showVerifyModal: false,
  
        loginFields: [
          { name: 'email', type: 'email', placeholder: 'Email', model: '' },
          { name: 'password', type: 'password', placeholder: 'Password', model: '' },
        ],

        forgotPasswordFields: [
            { name: 'email', type: 'email', placeholder: 'Enter your email', model: '' },
    
          ],
          resetPasswordFields: [
            { name: 'email', type: 'email', placeholder: 'Enter your email', model: '' },
            { name: 'password', type: 'password', placeholder: 'Enter new password', model: '' },
            { name: 'otp', type: 'text', placeholder: 'Enter the OTP sent to your email', model: '' },
          ],
          verifyFields: [
            { email: 'email', type: 'email', placeholder: 'Enter your email', model: '' },
            { v_code: 'v_code', type: 'text', placeholder: 'Enter the OTP sent to your email', model: '' },
    
    
          ],
        signupFields: [
          { name: 'fname', type: 'text', placeholder: 'First Name', model: '' },
          { name: 'sname', type: 'text', placeholder: 'Last Name', model: '' },
          { name: 'email', type: 'email', placeholder: 'Email', model: '' },
          { name: 'mobile', type: 'text', placeholder: 'Mobile', model: '' },
          { name: 'password', type: 'password', placeholder: 'Password', model: '' },
        ],
      };
    },
    methods: {



        openLoginModal() {
            this.showLoginModal = true;
          },
          openSignupModal() {
            this.showSignupModal = true;
          },
          openLoginModal() {
      
            this.showSignupModal = false,
              this.showForgotPasswordModal = false,
              this.showResetPasswordModal = false,
              this.showLoginModal = true
          },
          closeLoginModal() {
            this.showLoginModal = false
          },
          openSignupModal() {
            this.showLoginModal = false,
              this.showForgotPasswordModal = false,
              this.showResetPasswordModal = false,
              this.showSignupModal = true
          },
          closeSignupModal() {
            this.showSignupModal = false
          },
          openForgotPasswordModal() {
            this.showSignupModal = false,
              this.showResetPasswordModal = false,
              this.showLoginModal = false
            this.showForgotPasswordModal = true
          },
          closeForgotPasswordModal() {
            this.showForgotPasswordModal = false
          },
          openResetPasswordModal() {
            this.showSignupModal = false,
              this.showForgotPasswordModal = false,
              this.showLoginModal = false
      
            this.showResetPasswordModal = true
          },
          closeResetPasswordModal() {
            this.showResetPasswordModal = false
          },
          openVerifyModal() {
            this.showResetPasswordModal = false,
              this.showLoginModal = false,
              this.showSignupModal = false
            this.showVerifyModal = true
          },
          closeVerifyModal() {
            this.showVerifyModal = false
          },
      async login() {
        const credentials = {
          email: this.loginFields.find(field => field.name === 'email').model,
          password: this.loginFields.find(field => field.name === 'password').model,
        };
  
        try {
          const response = await fetch(process.env.VUE_APP_LOGIN, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(credentials),
          });
          const data = await response.json();
          if (response.ok) {
            alert('Login Successful!');
            localStorage.setItem('token', data.token);
            this.closeLoginModal();
          } else {
            alert(data.error || 'Login failed');
          }
        } catch (error) {
          console.error('Login Error:', error);
        }
      },
      async signup() {
        const credentials = {
          fname: this.signupFields.find(field => field.name === 'fname').model,
          sname: this.signupFields.find(field => field.name === 'sname').model,
          email: this.signupFields.find(field => field.name === 'email').model,
          mobile: this.signupFields.find(field => field.name === 'mobile').model,
          password: this.signupFields.find(field => field.name === 'password').model,
        };
        try {
          const response = await fetch(process.env.VUE_APP_SIGNUP, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(credentials),
          });
          const data = await response.json();
          if (response.ok) {
            alert('Signup Successful!');
            this.closeSignupModal();
            this.openVerifyModal();
          } else {
            alert(data.error || 'Signup failed');
          }
        } catch (error) {
          console.error('Signup Error:', error);
        }
      },
  



    requestOtp() {
        const emailData = { email: this.forgotPasswordFields.find(field => field.name === 'email').model };
        fetch(process.env.VUE_APP_FORGOT_PASSWORD, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(emailData),
        })
          .then(response => response.json())
          .then(data => {
            if (data.message) {
              alert(data.message);
              this.showForgotPasswordModal = false;
              this.showResetPasswordModal = true;
            } else {
              alert(data.error);
            }
          });
      },
  
      async verifyAccount() {
        const requestData = {
          email: this.verifyFields.find(field => field.email === 'email').model,
          v_code: this.verifyFields.find(field => field.v_code === 'v_code').model,
        };
        try {
          const response = await fetch(process.env.VUE_APP_VERIFY_ACCOUNT, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
          });
          const data = await response.json();
          if (response.ok) {
            alert(data.message);
            this.showVerifyModal = false;
            this.showLoginModal = true;
          } else {
            alert(data.error);
          }
  
        } catch (error) {
          alert(error.message);
          console.error('Verify Error:', error);
  
        }
  
      },
  
      // Reset Password API call
      resetPassword() {
        const resetData = {
          email: this.resetPasswordFields.find(field => field.name === 'email').model,
          password: this.resetPasswordFields.find(field => field.name === 'password').model,
          v_code: this.resetPasswordFields.find(field => field.name === 'otp').model,
        };
        fetch(process.env.VUE_APP_RESET_PASSWORD, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(resetData),
        })
          .then(response => response.json())
          .then(data => {
            if (data.message) {
              alert('Password reset successfully');
              this.showResetPasswordModal = false;
            } else {
              alert(data.error);
            }
          });
      },
  
      // Resend OTP API call
      resendOtp() {
        const emailData = { email: this.forgotPasswordFields.find(field => field.name === 'email').model };
        fetch(process.env.VUE_APP_RESEND_OTP, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(emailData),
        })
          .then(response => response.json())
          .then(data => {
            alert(data.message || data.error);
          });
      },
  
    },
  };
  