<template>
    <div class="modal-overlay">
        <div class="modal-content">
            <span class="close" @click="$emit('close')">&times;</span>
            <h2>Select Items for Pickup</h2>
            <div class="items-list">
                <div class="item" v-for="item in items" :key="item.id">
                    <span>{{ item.name }}</span>
                    <input type="number" class="input-field" v-model.number="item.quantity" min="0"
                        placeholder="Quantity" />
                </div>
            </div>
            <button @click="handleCheckout" class="submit-button" :disabled="loading || cartIsEmpty">
                <span v-if="loading" class="loading-spinner"></span>
                {{ loading ? "Processing..." : "Continue" }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ItemsModal',
    props: {
        items: { type: Array, required: true },
    },
    data() {
        return {
            loading: false,
            cartIsEmpty: true
        };
    },
    watch: {
        items: {
            handler(newItems) {
                // Update cartIsEmpty status based on the quantities in the items array
                this.cartIsEmpty = newItems.every(item => item.quantity === 0);
            },
            deep: true
        }
    },
    methods: {
        async handleCheckout() {
            const selectedItems = this.items.filter(item => item.quantity > 0);

            if (!this.cartIsEmpty) {
                this.loading = true; // Set loading to true while processing
                try {
                    await this.$emit('checkout', selectedItems); // Wait for the checkout process to complete
                } catch (error) {
                    console.error("Checkout error:", error);
                    alert("There was an error processing your request.");
                } finally {
                    this.loading = false; // Reset loading after the process completes
                }
            } else {
                alert("Please select at least one item before checking out.");
            }
        }
    }
}
</script>

<style scoped>
/* Same styles as login modal */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Overlay with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    /* Modal width */
    width: 100%;
    /* Take full width for responsiveness */
    max-height: 75vh;
    /* 75% of the viewport height */
    overflow-y: auto;
    /* Scroll if content is longer */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.text-button {
    margin-top: 10px;
    background: none;
    border: none;
    color: var(--dl-color-theme-primary1);
    cursor: pointer;
    text-decoration: underline;
}

h2 {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    text-align: center;
    color: #333;
}

.close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 2rem;
    cursor: pointer;
    color: #333;
}

.form-group {
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
}

.input-field {
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    width: 20%;
    box-sizing: border-box;
    transition: all 0.3s;
}

.input-field:focus {
    border-color: var(--dl-color-theme-primary1);
    /* Green border on focus */
    outline: none;
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);
    /* Subtle green shadow */
}

.submit-button {
    /* background-color: #4caf50; */
    background-color: var(--dl-color-theme-primary1);
    /* Green button */
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 5px;
    margin-top: 1rem;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: var(--dl-color-theme-neutral-dark);
    /* Darker green on hover */
}

.loading-spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 8px;
    /* Space between spinner and text */
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quantity-picker {
    display: flex;
    align-items: center;
}

.quantity-picker button {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    /* Adjust size as needed */
    cursor: pointer;
}

.quantity-picker input {
    width: 50px;
    /* Adjust width as needed */
    text-align: center;
    /* Center the text */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
