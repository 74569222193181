<template>
    <div class="orders-page">
        <h2>My Orders</h2>
        <div v-if="orders.length > 0">
            <div v-for="order in orders" :key="order.id" class="order-card">
                <p><strong>Items:</strong> </p>
                <ul class="items-list">
                    <li v-for="item in order.order_items" :key="item.id">
                        {{ item.quantity }} x {{ item.item_name }} {{ item.item_id }} ({{ item.amount }} USD)
                    </li>
                </ul>

                <p><strong>Address:</strong> {{ order.house_no }} {{ order.street_address }}</p>
                <p><strong>Pickup Date & Time:</strong> {{ formatDateTime(order.pickup_datetime) }}</p>
                <p><strong>Status:</strong> {{ order.status === 0 ? 'Pending' : 'Completed' }}</p>

            </div>
        </div>
        <div v-else>
            <p>No orders found.</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            orders: []
        };
    },
    methods: {
        fetchOrders() {
            const token = localStorage.getItem('token'); // Assuming you store the token in localStorage

            axios.post(process.env.VUE_APP_MY_ORDERS, {}, {
                headers: {
                    'Authorization': `${token}`
                }
            })
                .then(response => {
                    this.orders = response.data;
                })
                .catch(error => {
                    console.error('Error fetching orders:', error);
                });
        },
        formatDateTime(datetime) {
            const date = new Date(datetime);
            return date.toLocaleString();
        }
    },
    mounted() {
        this.fetchOrders();
    }
};
</script>

<style scoped>
.orders-page {
    padding: 20px;
}

.order-card {
    border: 1px solid #ccc;
    padding: 16px;
    margin-bottom: 20px;
    border-radius: 5px;
}

h2 {
    margin-bottom: 20px;
}

.items-list {
    list-style-type: none;
    margin-bottom: 10px;
}
</style>