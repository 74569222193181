<template>
    <div class="modal-overlay">
        <div class="modal-content">
            <span class="close" @click="$emit('close')">&times;</span>
            <h2>Order Summary</h2>
            <div class="summary-section">
                <h3>Location Details</h3>
                <p><strong>City:</strong> {{ locationData.city }}</p>
                <p><strong>Zipcode:</strong> {{ locationData.zip_code }}</p>
            </div>
            <div class="summary-section">
                <h3>Pickup Details</h3>
                <p><strong>House No:</strong> {{ pickupDetails.house_no }}</p>
                <p><strong>Street Address:</strong> {{ pickupDetails.street_address }}</p>
                <p><strong>Pickup Time:</strong> {{ new Date(pickupDetails.pickup_datetime).toLocaleString() }}</p>
                <p><strong>Contact Number:</strong> {{ pickupDetails.contact_mobile }}</p>
            </div>
            <div class="summary-section">
                <h3>Items Selected</h3>
                <ul>
                    <li v-for="item in cart" :key="item.item_id">
                        {{ item.name }} - Quantity: {{ item.quantity }}
                    </li>
                </ul>

            </div>
            <div class="summary-section">
                <h3>Total Charges</h3>
                Total: <strong>${{ cart.reduce((total, item) => total + (item.settings.amount * item.quantity), 0)
                    }}</strong>

            </div>
            <button @click="confirmOrder" class="submit-button" :disabled="loading">
                <span v-if="loading" class="loading-spinner"></span>
                {{ loading ? "Processing..." : "Confirm Order" }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SummaryModal',
    props: {
        locationData: { type: Object, required: true },
        pickupDetails: { type: Object, required: true },
        cart: { type: Array, required: true }
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        async confirmOrder() {
            this.loading = true;
            try {

                await this.$emit('confirmOrder');
            } catch (error) {
                console.error("Error during order confirmation:", error);
                // Optionally handle errors, e.g., display a message
                alert("There was an error confirming your order. Please try again.");
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>



<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Overlay with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    /* Modal width */
    width: 100%;
    /* Take full width for responsiveness */
    max-height: 75vh;
    /* 75% of the viewport height */
    overflow-y: auto;
    /* Scroll if content is longer */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.summary-section {
    margin-bottom: 20px;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: var(--dl-color-theme-primary1);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
</style>
